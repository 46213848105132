module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Braun Auctions","short_name":"Braun","start_url":"/","background_color":"#b7dc78","theme_color":"#b7dc78","display":"minimal-ui","icon":"src/images/braun-auctions-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b3abc04c9a824307862d63e928d321cd"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"braun","path":"/preview","previews":true,"pages":[{"type":"Content_page","match":"/:uid","path":"/","component":"/opt/build/repo/src/templates/Landing.jsx"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
